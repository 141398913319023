import React, {useEffect} from 'react';

function ScrollTop(){
  function ScrollToTopOnMount() {
    useEffect(() => {
      window.scrollTo(0, 0);
    }, []);
  
    return null;
  }
  return(
    <ScrollToTopOnMount />
  )
}
export default ScrollTop;