import React from 'react';
import './style.scss'
import Banner1 from '../../assets/images/banner1.jpg';
import Banner2 from '../../assets/images/banner2.jpg';
import Banner3 from '../../assets/images/banner3.jpg';
import Slider from 'react-animated-slider';
import 'react-animated-slider/build/horizontal.css';
import './slider-animations.scss';
import './style.scss';

const content = [
	{
		title: 'Mais de 25 anos de atuação nas áreas de contabilidade fiscal, trabalhista e legal.',
		image: Banner1
	},
	{
		title: 'Equipe capacitada para oferecer serviços de qualidade, sempre com atenção a cada detalhe.',
		image: Banner2
	},
	{
		title: 'Equipamentos e sistemas com tecnologia de ponta para garantir a segurança dos dados. ',
		image: Banner3
	}
];

const App = () => (
	<div className="sl-show">
		<Slider className="slider-wrapper" autoplay={3000}>
			{content.map((item, index) => (
				<div
					key={index}
					className="slider-content"
					style={{ background: `url('${item.image}') no-repeat center center` }}
				>
					<div className="inner">
						<h1>{item.title}</h1>
					</div>
				</div>
			))}
		</Slider>
	</div>
);

export default App;
