import React from 'react';

import Menu from '../../../Components/menu';
import Footer from '../../../Components/footer';
function Federal(){
    return(
        <div>
            <Menu />
            <div className="table__simples">
                <div>
                    <h3 class="text-center"> TABELA Tributação Federal Lucro Presumido: Comércio e Indústria</h3>
                    <div class="textotabela">
                    <table class="table" width="100%" border="0" align="center" cellpadding="0" cellspacing="1">
                    <tbody><tr>
                      <td height="46" colspan="2" align="center" valign="middle" bgcolor="#b3b5b7"><strong>Tributação Federal Lucro Presumido: Comércio e Indústria</strong></td>
                    </tr>
                    <tr align="left" valign="top">
                      <td height="89" colspan="2" align="center" valign="middle" bgcolor="#ffffff">Pagamento dos Impostos, DARF'S sobre o faturamento, conforme tabela abaixo: <br />
                        <br />
                        Comércio e Indústria (Fat X 8% = Lucro Presumido X 15%)</td>
                    </tr>
                    <tr bgcolor="#CBDCED">
                      <td height="22" align="center" valign="middle" bgcolor="#ffffff">&nbsp;</td>
                      <td height="22" align="center" valign="middle" bgcolor="#ffffff"><strong>Comércio e Indústria</strong></td>
                      </tr>
                    <tr bgcolor="#CBDCED">
                      <td height="22" align="center" valign="middle" bgcolor="#ffffff"><strong>IRPJ</strong></td>
                      <td height="22" align="center" valign="middle" bgcolor="#ffffff">1,20 %</td>
                      </tr>
                    <tr bgcolor="#CBDCED">
                      <td height="22" align="center" valign="middle" bgcolor="#ffffff"><strong>Contribuição Social</strong></td>
                      <td height="22" align="center" valign="middle" bgcolor="#ffffff">1,08 %</td>
                      </tr>
                    <tr bgcolor="#CBDCED">
                      <td height="22" align="center" valign="middle" bgcolor="#ffffff"><strong>PIS</strong></td>
                      <td height="22" align="center" valign="middle" bgcolor="#ffffff">0,65 %</td>
                      </tr>
                    <tr bgcolor="#CBDCED">
                      <td height="22" align="center" valign="middle" bgcolor="#ffffff"><strong>COFINS</strong></td>
                      <td height="22" align="center" valign="middle" bgcolor="#ffffff">3,00 %<br />-----------------</td>
                      </tr>
                    <tr bgcolor="#CBDCED">
                      <td height="37" align="center" valign="middle" bgcolor="#b3b5b7"><strong>TOTAL</strong></td>
                      <td height="37" align="center" valign="middle" bgcolor="#b3b5b7">5,93%</td>
                      </tr>
                    <tr>
                      <td height="auto" colspan="2" align="left" valign="middle" bgcolor="#ffffff">
                                           <strong>1 - PIS e COFINS</strong>, apuração mensal, e vencimento no dia 25 do mês seguinte.<br />
                                           <strong>2 - IRPJ e Contribuição Socia</strong>l, apuração trimestral, vencimento no último dia útil de Abril, Julho, Outubro e Janeiro.<br />
                                           <strong>3 - Impostos Federais</strong>, antecipar o pagamento, caso o vencimento não caia em dia útil.<br />
                                           <strong>4 - Limite de Faturamento:</strong> R$ 48.000.000,00/ano.
                      </td>
                    </tr>
                    <tr bgcolor="#CBDCED">
                      <td height="22" align="center" valign="middle" bgcolor="#ffffff"><strong>Exemplo:</strong></td>
                      <td height="22" align="center" valign="middle" bgcolor="#ffffff"><strong>Comércio</strong></td>
                      </tr>
                    <tr bgcolor="#CBDCED">
                      <td height="22" align="center" valign="middle" bgcolor="#ffffff">&nbsp;</td>
                      <td height="22" align="center" valign="middle" bgcolor="#ffffff">420.000,00</td>
                      </tr>
                    <tr bgcolor="#CBDCED">
                      <td height="22" align="center" valign="middle" bgcolor="#ffffff">&nbsp;</td>
                      <td height="22" align="center" valign="middle" bgcolor="#ffffff">X 8 %<br />
                      ----------------- </td>
                      </tr>
                    <tr bgcolor="#CBDCED">
                      <td height="22" align="center" valign="middle" bgcolor="#ffffff">&nbsp;</td>
                      <td height="22" align="center" valign="middle" bgcolor="#ffffff">33.600,00</td>
                      </tr>
                    <tr>
                      <td height="22" colspan="2" align="left" valign="middle" bgcolor="#ffffff"><br />
                                            <strong>Obs1.: INSS 20% </strong>sobre a retirada de Pró-Labore e sobre os Serviços Prestados para Pessoa Física.<br />
                      <strong>Obs2.: INSS 11%</strong> sobre a retirada de Pró-Labore para Contribuição de Aposentadoria.<br />
                    </td>
                    </tr>
                    </tbody></table>
                    </div>
                </div>
            </div>
            
            <Footer />
        </div>

    )
}
export default Federal;