import React from 'react';
import { Switch, Route} from 'react-router-dom';
import Home from './pages/home';
import Empresa from './pages/empresa';
import Servicos from './pages/servicos';
import Indices from './pages/indices';
import Links from './pages/links';
import Simples from './pages/subpages/simples';
import Federal from './pages/subpages/federal';
import Vencimentos from './pages/subpages/vencimentos';
import Contribuicoes from './pages/subpages/contribuicoes';
import Salarios from './pages/subpages/salario';
import Irenda from './pages/subpages/impostoderenda';
import Contato from './pages/contato';
function Routes(){
    
    return(
        <Switch>
            <Route path="/" exact component={Home} />
            <Route path="/empresa/" component={Empresa} />
            <Route path="/servicos" component={Servicos} />
            <Route path="/indices" component={Indices} />
            <Route path="/links" component={Links} />
            <Route path="/simples" component={Simples} />
            <Route path="/federal" component={Federal} />
            <Route path="/vencimentos" component={Vencimentos} />
            <Route path="/contribuicoes" component={Contribuicoes} />
            <Route path="/salarios" component={Salarios} />
            <Route path="/impostoderenda" component={Irenda} />
            <Route path="/contato" component={Contato} />
        </Switch>
    )
}
export default Routes;