import React from 'react';
import './style.scss';
import Menu from '../../../Components/menu';
import Footer from '../../../Components/footer';
function Simples(){
    return(
        <div>
            <Menu />
            <div className="table__simples">
                <div>
                    <h3 class="text-center"> TABELA Simples Nacional</h3>
                    <p><strong>Anexo I -</strong> Comércio</p>
                    <div className="table-responsive">
                        <table className="table table-bordered table-hover text-center ">
                        <thead>
                            <tr className="g-bg-heading text-uppercase">
                            <th>Receita bruta em 12 meses</th>
                            <th>Alíquota</th>
                            <th>Desconto sobre o valor recolhido</th>
                            </tr>
                        </thead>
                        <tbody className="g-bg-heading2">
                            <tr>
                            <th scope="row">Até R$ 180 mil</th>
                            <td>4%</td>
                            <td>
                                -
                            </td>
                            </tr>
                            <tr>
                            <th scope="row">De R$ 180.000,01 a R$ 360 mil</th>
                            <td>7,3%</td>
                            <td>R$ 5.940,00</td>
                            </tr>
                            <tr>
                            <th scope="row">De R$ 360.000,01 a R$ 720 mil</th>
                            <td>9,5%</td>
                            <td>R$ 13.860,00</td>
                            </tr>
                            <tr>
                            <th scope="row">De R$ 720.000,01 a R$ 1,8 milhão</th>
                            <td>10,7%</td>
                            <td>R$ 22.500,00</td>
                            </tr>
                            <tr>
                            <th scope="row">De R$ 1.800.000,01 a R$ 3,6 milhões</th>
                            <td>14,3%</td>
                            <td>R$ 87.300,00</td>
                            </tr>
                            <tr>
                            <th scope="row">R$ 3.600.000,01 a R$ 4,8 milhões</th>
                            <td>19%</td>
                            <td>R$ 378.000,00</td>
                            </tr>				  														  
                        </tbody>
                        </table>
                    </div>
                </div>
                <br /><br />
                <p><strong>Anexo II -</strong> Indústria</p>
                <div class="table-responsive">
                    <table class="table table-bordered table-hover text-center ">
                    <thead>
                        <tr class="g-bg-heading text-uppercase">
                        <th>Receita bruta em 12 meses</th>
                        <th>Alíquota</th>
                        <th>Desconto sobre o valor recolhido</th>
                        </tr>
                    </thead>
                    <tbody class="g-bg-heading2">
                        <tr>
                        <th scope="row">Até R$ 180 mil</th>
                        <td>4,5%</td>
                        <td>
                            <i class="fa fa-minus"></i>
                        </td>
                        </tr>
                        <tr>
                        <th scope="row">De R$ 180.000,01 a R$ 360 mil</th>
                        <td>7,8%</td>
                        <td>R$ 5.940,00</td>
                        </tr>
                        <tr>
                        <th scope="row">De R$ 360.000,01 a R$ 720 mil</th>
                        <td>10%</td>
                        <td>R$ 13.860,00</td>
                        </tr>
                        <tr>
                        <th scope="row">De R$ 720.000,01 a R$ 1,8 milhão</th>
                        <td>11,2%</td>
                        <td>R$ 22.500,00</td>
                        </tr>
                        <tr>
                        <th scope="row">De R$ 1.800.000,01 a R$ 3,6 milhões</th>
                        <td>14,7%</td>
                        <td>R$ 85.000,00</td>
                        </tr>
                        <tr>
                        <th scope="row">R$ 3.600.000,01 a R$ 4,8 milhões</th>
                        <td>30%</td>
                        <td>R$ 720.000,00</td>
                        </tr>				  														  
                    </tbody>
                    </table>
                </div>
                <br /><br />
                <p><strong>Anexo III -</strong> Serviços que aparecem nos § 5º-B, § 5º-D e § 5º-F do artigo 18 da Lei Complementar 123</p>
                <div class="table-responsive">
                    <table class="table table-bordered table-hover text-center ">
                    <thead>
                        <tr class="g-bg-heading text-uppercase">
                        <th>Receita bruta em 12 meses</th>
                        <th>Alíquota</th>
                        <th>Desconto sobre o valor recolhido</th>
                        </tr>
                    </thead>

                    <tbody class="g-bg-heading2">
                        <tr>
                        <th scope="row">Até R$ 180 mil</th>
                        <td>6%</td>
                        <td>
                            <i class="fa fa-minus"></i>
                        </td>
                        </tr>
                        <tr>
                        <th scope="row">De R$ 180.000,01 a R$ 360 mil</th>
                        <td>11,2%</td>
                        <td>R$ 9.360,00</td>
                        </tr>
                        <tr>
                        <th scope="row">De R$ 360.000,01 a R$ 720 mil</th>
                        <td>13,5%</td>
                        <td>R$ 17.640,00</td>
                        </tr>
                        <tr>
                        <th scope="row">De R$ 720.000,01 a R$ 1,8 milhão</th>
                        <td>16%</td>
                        <td>R$ 35.640,00</td>
                        </tr>
                        <tr>
                        <th scope="row">De R$ 1.800.000,01 a R$ 3,6 milhões</th>
                        <td>21%</td>
                        <td>R$ 125.640,00</td>
                        </tr>
                        <tr>
                        <th scope="row">R$ 3.600.000,01 a R$ 4,8 milhões</th>
                        <td>33%</td>
                        <td>R$ 648.000,00</td>
                        </tr>				  														  
                    </tbody>
                    </table>
                </div>
                <br /><br />
                <p><strong>Anexo IV -</strong> Serviços listados no § 5º-C do artigo 18 da Lei Complementar 123</p>
                <div class="table-responsive">
                    <table class="table table-bordered table-hover text-center ">
                    <thead>
                        <tr class="g-bg-heading text-uppercase">
                        <th>Receita bruta em 12 meses</th>
                        <th>Alíquota</th>
                        <th>Desconto sobre o valor recolhido</th>
                        </tr>
                    </thead>

                    <tbody class="g-bg-heading2">
                        <tr>
                        <th scope="row">Até R$ 180 mil</th>
                        <td>4,5%</td>
                        <td>
                            <i class="fa fa-minus"></i>
                        </td>
                        </tr>
                        <tr>
                        <th scope="row">De R$ 180.000,01 a R$ 360 mil</th>
                        <td>9%</td>
                        <td>R$ 8.100,00</td>
                        </tr>
                        <tr>
                        <th scope="row">De R$ 360.000,01 a R$ 720 mil</th>
                        <td>10,2%</td>
                        <td>R$ 12.420,00</td>
                        </tr>
                        <tr>
                        <th scope="row">De R$ 720.000,01 a R$ 1,8 milhão</th>
                        <td>14%</td>
                        <td>R$ 39.780,00</td>
                        </tr>
                        <tr>
                        <th scope="row">De R$ 1.800.000,01 a R$ 3,6 milhões</th>
                        <td>22%</td>
                        <td>R$ 183.780,00</td>
                        </tr>
                        <tr>
                        <th scope="row">R$ 3.600.000,01 a R$ 4,8 milhões</th>
                        <td>33%</td>
                        <td>R$ 828.000,00</td>
                        </tr>				  														  
                    </tbody>
                    </table>
                </div>
                <br /><br />
                <p><strong>Anexo V -</strong> Serviços que constam do § 5º-I do artigo 18 da Lei Complementar 123</p>
                <div class="table-responsive">
                    <table class="table table-bordered table-hover text-center ">
                    <thead>
                        <tr class="g-bg-heading text-uppercase">
                        <th>Receita bruta em 12 meses</th>
                        <th>Alíquota</th>
                        <th>Desconto sobre o valor recolhido</th>
                        </tr>
                    </thead>

                    <tbody class="g-bg-heading2">
                        <tr>
                        <th scope="row">Até R$ 180 mil</th>
                        <td>15,5%</td>
                        <td>
                            <i class="fa fa-minus"></i>
                        </td>
                        </tr>
                        <tr>
                        <th scope="row">De R$ 180.000,01 a R$ 360 mil</th>
                        <td>18%</td>
                        <td>R$ 4.500,00</td>
                        </tr>
                        <tr>
                        <th scope="row">De R$ 360.000,01 a R$ 720 mil</th>
                        <td>19,5%</td>
                        <td>R$ 9.900,00</td>
                        </tr>
                        <tr>
                        <th scope="row">De R$ 720.000,01 a R$ 1,8 milhão</th>
                        <td>20,5%</td>
                        <td>R$ 17.100,00</td>
                        </tr>
                        <tr>
                        <th scope="row">De R$ 1.800.000,01 a R$ 3,6 milhões</th>
                        <td>23%</td>
                        <td>R$ 62.100,00</td>
                        </tr>
                        <tr>
                        <th scope="row">R$ 3.600.000,01 a R$ 4,8 milhões</th>
                        <td>30,5%</td>
                        <td>R$ 540.000,00</td>
                        </tr>				  														  
                    </tbody>
                    </table>
                </div>
            </div>
            <Footer />
        </div>
    )
}
export default Simples;