import React from 'react';
import './style.scss';
import ImgIndice from '../../assets/images/tabelas_1.jpg';
import ImgLinks from '../../assets/images/links.jpg';
import { Link } from 'react-router-dom';
function Indices(){
    return(
        <div className="indices">
            <div className="indices__content">
                <div className="indices__box">
                    <Link to="/indices">
                        <div className="indices--enable">
                            <p>Indices e Tabelas</p>
                        </div>
                        <img src={ImgIndice} alt="Logo Futuro"/>
                        <div className="indices__text">
                            <p>Indices e Tabelas</p>
                        </div>
                    </Link>
                </div>
                <div className="indices__box">
                    <Link to="/links">
                        <div className="indices--enable">
                            <p>Links Úteis</p>
                        </div>
                        <img src={ImgLinks} alt="Logo Futuro"/>
                        <div className="indices__text">
                            <p>Links Úteis</p>
                        </div>
                    </Link>
                </div>
            </div>
        </div>
    )
}
export default Indices;