import React, {useState} from 'react';
import './style.scss';
import { FaInstagram, FaFacebook, FaWhatsapp } from 'react-icons/fa';
import { MdDehaze } from 'react-icons/md';
import { Link } from 'react-router-dom';
import Logo from '../../assets/images/logo.png';
import LogoM2 from '../../assets/images/Logo_Futuro_mobile1.png';

function Menu(){
    const [mobile, setMobile] = useState(false);
    function handleClick() {
        if(mobile === false){
            setMobile(true);
        }else{
            setMobile(false);
        }
    }
    return(
        <div className="menu">
            <div className="menu__content">
                <nav>
                    <ul>
                        <li><Link to="/empresa">A Empresa</Link></li>
                        <li><Link to="/servicos">Serviços</Link></li>
                        {/* <li><Link to="/blog">Blog</Link></li> */}
                        <li><Link to="/"><img src={Logo} alt="Logo Futuro"/></Link></li>
                        <li><Link to="/indices">Índices</Link></li>
                        <li><Link to="/links">Links</Link></li>
                        <li><Link to="/contato">Contato</Link></li>
                    </ul>
                </nav>
                <div className="menu__rsociais">
                    <a href="https://wa.me/5511992963793" target="_blank"><FaWhatsapp size={18}/></a>
                    <a href="https://www.facebook.com/Futuro-Assessoria-Cont%C3%A1bil-109286837920445" target="_blank"><FaFacebook size={18}/></a>
                    <a href="https://instagram.com/futuro_assessoriacontabil?igshid=po9b6p3v7cav" target="_blank"><FaInstagram size={18}/></a>
                </div>
            </div>
            <div className="menu__mobile">
                <div className="menu__icons">
                    <Link to="/"><img src={LogoM2} alt="Logo Futuro" className="igm"/></Link>
                    <MdDehaze size={28} onClick={handleClick}/>
                </div>
                <div className={mobile === true ? 'menu__select--on' : 'menu__select--off'}>
                    <nav>
                        <ul>
                            <li><Link to="/empresa">A Empresa</Link></li>
                            <li><Link to="/servicos">Serviços</Link></li>
                            {/* <li><Link to="/blog">Blog</Link></li> */}
                            <li><Link to="/indices">Índices</Link></li>
                            <li><Link to="/links">Links</Link></li>
                            <li><Link to="/contato">Contato</Link></li>
                        </ul>
                    </nav>
                    <div className="menu__rsociais">
                        <a href="https://wa.me/5511992963793" target="_blank"><FaWhatsapp size={18}/></a>
                        <a href="https://www.facebook.com/Futuro-Assessoria-Cont%C3%A1bil-109286837920445" target="_blank"><FaFacebook size={18}/></a>
                        <a href="https://instagram.com/futuro_assessoriacontabil?igshid=po9b6p3v7cav" target="_blank"><FaInstagram size={18}/></a>
                    </div>
                </div>
                
            </div>
        </div>
    )
}
export default Menu;