import React from 'react';
import './style.scss';
import Menu from '../../Components/menu';
import Footer from '../../Components/footer';
import ImgCaixa from '../../assets/images/caixa.jpg';
import ImgFgts from '../../assets/images/fgts.jpg';
import ImgGovbr from '../../assets/images/govbr.jpg';
import ImgPrefeitura from '../../assets/images/prefeitura.jpg';
import ImgInss from '../../assets/images/inss.jpg';
import ImgReceita from '../../assets/images/receita.jpg';
import ImgSimples from '../../assets/images/simples.jpg';
import ImgSintegra from '../../assets/images/sintegra.jpg';
import ScrollTop from '../../Components/ScrollTop';
function Indice(){
    return(
        <div>
            <ScrollTop />
             <Menu />
                <div className="links">
                    <div className="links__boxes">
                        <div className="links__box">
                            <a href="http://www.caixa.gov.br/" target="_blank"><img src={ImgCaixa} alt="Caixa" /></a>
                        </div>
                        <div className="links__box">
                            <a href="http://www.fgts.gov.br/" target="_blank"><img src={ImgFgts} alt="FGTS" /></a>
                        </div>
                        <div className="links__box">
                            <a href="http://www.trabalho.gov.br/" target="_blank"><img src={ImgGovbr} alt="Ministério do trabalho" /></a>
                        </div>
                        <div className="links__box">
                            <a href="http://prefeitura.sp.gov.br/" target="_blank"><img src={ImgPrefeitura} alt="Prefeitura de São Paulo" /></a>
                        </div>
                        <div className="links__box">
                            <a href="http://www.previdencia.gov.br/" target="_blank"><img src={ImgInss} alt="Previdência Social" /></a>
                        </div>
                        <div className="links__box">
                            <a href="http://www.receita.fazenda.gov.br/" target="_blank"><img src={ImgReceita} alt="Receita Federal" /></a>
                        </div>
                        <div className="links__box">
                            <a href="http://www8.receita.fazenda.gov.br/simplesnacional/" target="_blank"><img src={ImgSimples} alt="Simples Nacional" /></a>
                        </div>
                        <div className="links__box">
                            <a href="http://www.sintegra.gov.br/" target="_blank"><img src={ImgSintegra} alt="Sintegra" /></a>
                        </div>
                    </div>
                </div>
             <Footer />
        </div>
    )
}
export default Indice;