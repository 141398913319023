import React from 'react';
import Menu from '../../../Components/menu';
import Footer from '../../../Components/footer';
function Contribuicoes(){
    return(
        <div>
            <Menu />
            <div className="table__simples">
                <div>
                <div id="trabalhador">            
                    <p>&nbsp;</p>
                    <h3 class="text-center"> TABELA Contribuições Previdenciárias / INSS - A partir de 01/03/2020</h3>
                    <p class="text-center"><b>Segurado Empregado e Trabalhador Avulso</b></p>
                    <div class="row">
                        <div class="table-responsive">
                            <table width="100%" border="1" align="center" cellpadding="1" cellspacing="1">
                            <tbody><tr>
                            <td width="50%" height="40" align="center" bgcolor="#b3b5b7" class="textotabela">Salário de Contribuição</td>
                            <td width="50%" height="40" align="center" bgcolor="#b3b5b7" class="textotabela">Alíquota</td>
                            </tr>
                            <tr>
                            <td height="40" align="center" bgcolor="#ffffff" class="textotabela">Até R$ 1.045,00</td>
                            <td height="40" align="center" bgcolor="#ffffff" class="textotabela">7,50%</td>
                            </tr>
                            <tr>
                            <td height="40" align="center" bgcolor="#ffffff" class="textotabela">De R$ 1.045,01 até R$ 2.089,60</td>
                            <td height="40" align="center" bgcolor="#ffffff" class="textotabela">9%</td>
                            </tr>
                            <tr>
                            <td height="40" align="center" bgcolor="#ffffff" class="textotabela">De R$ 2.089,61 até R$ 3.134,40</td>
                            <td height="40" align="center" bgcolor="#ffffff" class="textotabela">12%</td>
                            </tr>
                            <tr>
                            <td height="40" align="center" bgcolor="#ffffff" class="textotabela">De R$ 3.134,41 até R$ 6.101,06</td>
                            <td height="40" align="center" bgcolor="#ffffff" class="textotabela">14%</td>
                            </tr>                   
                            <tr>
                            <td height="40" align="center" bgcolor="#ffffff" class="textotabela">Acima de R$ 6.101,06</td>
                            <td height="40" align="center" bgcolor="#ffffff" class="textotabela">R$ 713,08</td>
                            </tr>                   
                            </tbody></table>
                            <br /><p><strong>Obs:</strong> Para IRRF ver a Tabela Imposto de Renda Retido na Fonte.</p>                     
                        </div>  
                    </div>              
                    </div>
                
                    <div id="domestico">            
                        <p>&nbsp;</p>
                        <h3 class="text-center"><i class="fa fa-table"></i> TABELA Contribuições Previdenciárias / INSS - A partir de 01/03/2020</h3>
                        <p class="text-center"><b>Segurado Empregado Doméstico (Tabela para orientação do empregador doméstico)</b></p>
                        <div class="row">
                            <div class="table-responsive">
                            <table width="100%" border="1" align="center" cellpadding="1" cellspacing="1">
                                <tbody><tr>
                                <td colspan="2" rowspan="2" align="center" bgcolor="#b3b5b7" class="textotabela">Salário de Contribuição <br />
                                (R$)</td>
                                <td height="40" colspan="2" align="center" bgcolor="#b3b5b7" class="textotabela">INSS</td>
                                <td width="13%" rowspan="2" align="center" bgcolor="#b3b5b7" class="textotabela">FGTS</td>
                                <td width="14%" rowspan="2" align="center" bgcolor="#b3b5b7" class="textotabela">Seguro <br />
                                Acidente <br />
                                Trabalho</td>
                                <td width="12%" rowspan="2" align="center" bgcolor="#b3b5b7" class="textotabela"><p>Indenização<br />
                                Perda <br />
                                Emprego</p></td>
                                </tr>
                                <tr>
                                <td width="12%" align="center" bgcolor="#b3b5b7" class="textotabela">Empregado</td>
                                <td width="11%" height="40" align="center" bgcolor="#b3b5b7" class="textotabela">Empregador</td>
                                </tr>
                                <tr>
                                <td height="40" colspan="2" align="center" bgcolor="#ffffff" class="textotabela">Até R$ 1.045,00</td>
                                <td align="center" bgcolor="#ffffff" class="textotabela">7,50%</td>
                                <td height="40" align="center" bgcolor="#ffffff" class="textotabela">8%</td>
                                <td height="40" align="center" bgcolor="#ffffff" class="textotabela">8%</td>
                                <td height="40" align="center" bgcolor="#ffffff" class="textotabela">0,8%</td>
                                <td align="center" bgcolor="#ffffff" class="textotabela">3,2%</td>
                                </tr>
                                <tr>
                                <td height="40" colspan="2" align="center" bgcolor="#ffffff" class="textotabela">De R$ 1.045,01 até R$ 2.089,60</td>
                                <td align="center" bgcolor="#ffffff" class="textotabela">9%</td>
                                <td height="40" align="center" bgcolor="#ffffff" class="textotabela">8%</td>
                                <td height="40" align="center" bgcolor="#ffffff" class="textotabela">8%</td>
                                <td height="40" align="center" bgcolor="#ffffff" class="textotabela">0,8%</td>
                                <td align="center" bgcolor="#ffffff" class="textotabela">3,2%</td>
                                </tr>
                                <tr>
                                <td height="40" colspan="2" align="center" bgcolor="#ffffff" class="textotabela">De R$ 2.089,61 até R$ 3.134,40</td>
                                <td align="center" bgcolor="#ffffff" class="textotabela">12%</td>
                                <td height="40" align="center" bgcolor="#ffffff" class="textotabela">8%</td>
                                <td height="40" align="center" bgcolor="#ffffff" class="textotabela">8%</td>
                                <td height="40" align="center" bgcolor="#ffffff" class="textotabela">0,8%</td>
                                <td align="center" bgcolor="#ffffff" class="textotabela">3,2%</td>
                                </tr>
                                <tr>
                                <td height="40" colspan="2" align="center" bgcolor="#ffffff" class="textotabela">De R$ 3.134,41 até R$ 6.101,06</td>
                                <td align="center" bgcolor="#ffffff" class="textotabela">14%</td>
                                <td height="40" align="center" bgcolor="#ffffff" class="textotabela">8%</td>
                                <td height="40" align="center" bgcolor="#ffffff" class="textotabela">8%</td>
                                <td height="40" align="center" bgcolor="#ffffff" class="textotabela">0,8%</td>
                                <td align="center" bgcolor="#ffffff" class="textotabela">3,2%</td>
                                </tr>                   
                                <tr>
                                <td height="40" colspan="2" align="center" bgcolor="#ffffff" class="textotabela">Acima de R$ 6.101,06</td>
                                <td align="center" bgcolor="#ffffff" class="textotabela">R$ 713,08</td>
                                <td height="40" align="center" bgcolor="#ffffff" class="textotabela">8%</td>
                                <td height="40" align="center" bgcolor="#ffffff" class="textotabela">8%</td>
                                <td height="40" align="center" bgcolor="#ffffff" class="textotabela">0,8%</td>
                                <td align="center" bgcolor="#ffffff" class="textotabela">3,2%</td>
                                </tr>
                            </tbody></table>
                            <br /><p><strong>Obs:</strong> Para IRRF ver a Tabela Imposto de Renda Retido na Fonte.</p>  
                            </div>  
                        </div>              
                        </div>
                        <div id="individual">           
                            <p>&nbsp;</p>
                            <h3 class="text-center"><i class="fa fa-table"></i> TABELA Contribuições Previdenciárias / INSS - A partir de 01/01/2020</h3>
                            <p class="text-center"><b>Contribuinte Individual e Facultativo</b></p>
                            <div class="row">
                                <div class="table-responsive">
                                    <table width="100%" border="1" align="center" cellpadding="1" cellspacing="1">
                                    <tbody><tr>
                                    <td width="15%" height="40" align="center" bgcolor="#b3b5b7" class="textotabela">Salário de Contribuição</td>
                                    <td width="50%" height="40" align="center" bgcolor="#b3b5b7" class="textotabela">Aliquota</td>
                                    <td width="35%" height="40" align="center" bgcolor="#b3b5b7" class="textotabela">Valor</td>                     
                                    </tr>
                                    <tr>
                                    <td height="40" align="center" bgcolor="#ffffff" class="textotabela">R$ 1.045,00</td>
                                    <td height="40" align="center" bgcolor="#ffffff" class="textotabela"> 5% (não dá direito a Aposentadoria por Tempo de Contribuição e Certidão de Tempo de Contribuição)*</td>
                                    <td height="40" align="center" bgcolor="#ffffff" class="textotabela">R$ 52,25</td>                      
                                    </tr>
                                    <tr>
                                    <td height="40" align="center" bgcolor="#ffffff" class="textotabela">R$ 1.045,00</td>
                                    <td height="40" align="center" bgcolor="#ffffff" class="textotabela"> 11% (não dá direito a Aposentadoria por Tempo de Contribuição e Certidão de Tempo de Contribuição)**</td>
                                    <td height="40" align="center" bgcolor="#ffffff" class="textotabela">R$ 114,95</td>                     
                                    </tr>
                                    <tr>
                                    <td height="40" align="center" bgcolor="#ffffff" class="textotabela">R$ 1.045,00 até R$ 6.101,06</td>
                                    <td height="40" align="center" bgcolor="#ffffff" class="textotabela">20%</td>
                                    <td height="40" align="center" bgcolor="#ffffff" class="textotabela"> Entre R$ 209,00 (salário mínimo) e R$ 1.220,21 (teto)</td>                      
                                    </tr>                   

                                    </tbody></table>
                                    <br /><p><strong>*</strong> Alíquota exclusiva do Facultativo Baixa Renda;
                                    <br /><strong>**</strong> Alíquota exclusiva do Plano Simplificado de Previdência;
                                </p></div>  
                            </div>              
                        </div>
                </div>
            </div>
            
            <Footer />
        </div>

    )
}
export default Contribuicoes;