import React, {useState} from 'react';
import './style.scss';
import Menu from '../../Components/menu';
import Footer from '../../Components/footer';
import ImgCont from '../../assets/images/contabilidade-s.jpg';
import ImgFiscal from '../../assets/images/fiscal-s.jpg';
import ImgDepart from '../../assets/images/depart-s.jpg';
import ImgLegal from '../../assets/images/legal-s.jpg';
import ScrollTop from '../../Components/ScrollTop';
function Servicos(){
    const services = [
        {
            Tipo: "Contabilidade", 
            img: ImgCont
        },
        {
            Tipo: "Fiscal / Tributário", 
            img: ImgFiscal
        },
        {
            Tipo: "Departamento Pessoal", 
            img: ImgDepart
        },
        {
            Tipo: "Legal / Outros", 
            img: ImgLegal
        }
    ]
    const [toggle, setToggle] = useState('');
    return(
        <div>
            <ScrollTop />
            <Menu />
                <div className="servicos">
                    <div className="servicos__img">
                    {services.map((serv, id) => (
                        <div key={id} className={"servicos__box"} onClick={() => setToggle(serv.Tipo)}>
                            <div className={toggle === serv.Tipo ? 'off' : 'servicos--enable'}>{serv.Tipo}</div>
                            <img src={serv.img} alt={serv.Tipo} />
                        </div>
                        
                    ))}
                        <div className="servicos__text">
                            <h2>{toggle}</h2>
                            <div>
                                {
                                toggle === "Contabilidade" && <p>Planejamento Tributário na busca de alternativas para excluir, reduzir ou adiar o ônus fiscal e assim dar mais competitividade para sua empresa.</p> &&  
                                    <ul>
                                        <li>Planejamento Societário para reduzir a carga tributária e preparar o futuro da empresa. </li>
                                        <li>Escrituração Eletrônica dos Livros, apuração de impostos e guias relacionadas a todos os órgãos.</li>
                                        <li>Atendimento personalizado às exigências fiscais da legislação vigente.</li>
                                        <li>Execução das demais rotinas e relatórios mensais e anuais.</li>
                                    </ul>
                                    }
                                    {toggle === "Fiscal / Tributário" && <p>Planejamento Tributário na busca de alternativas para excluir, reduzir ou adiar o ônus fiscal e assim dar mais competitividade para sua empresa.</p> &&  
                                    <ul>
                                        <li>Planejamento Tributário, voltado para o estudo de alternativas para excluir, reduzir ou adiar o ônus fiscal, propiciando mais competitividade para sua empresa.</li>
                                        <li>Planejamento Societário visando a redução da carga tributária e a preparação para o futuro, com vistas à sucessão.</li>
                                        <li>Escrituração Eletrônica dos Livros, apuração de impostos e guias, com relação a todos os órgãos.</li>
                                        <li>Atendimento personalizado, de acordo com as exigências fiscais da legislação vigente.</li>
                                        <li>Execução das demais rotinas e relatórios mensais e anuais.</li>
                                    </ul>
                                    }
                                    {toggle === "Departamento Pessoal"  &&  
                                    <ul>
                                        <li>Assessoria e execução das rotinas de admissão e demissão de funcionários. </li>
                                        <li>Homologações de colaboradores com acompanhamento de nosso funcionário no Sindicato.</li>
                                        <li>Fornecimento de Informe de rendimentos para os funcionários na ocasião da Declaração do Imposto de Renda.</li>
                                        <li>Cálculo e elaboração de Férias e da Folha de Pagamento.</li>
                                        <li>Acompanhamento dos acordos e dissídios coletivos.</li>
                                        <li>Gestão e execução das demais rotinas, impostos, relatórios mensais e anuais.</li>
                                    </ul>
                                    }
                                    {toggle === "Legal / Outros" && <p>Planejamento Tributário na busca de alternativas para excluir, reduzir ou adiar o ônus fiscal e assim dar mais competitividade para sua empresa.</p> &&
                                    <div className="servicos__legal">
                                        <ul>
                                            <li>Consultoria e assessoria jurídica para a área contábil da sua empresa.</li>
                                            <li>Elaboração e revisão de contratos em geral.</li>
                                            <li>Consultoria em leis trabalhistas e provisórias.</li>
                                            <li>Abertura, alteração e encerramento de empresas.</li>
                                        </ul>
                                        <ul>
                                            <li>Orientação e execução de todos os serviços burocráticos da empresa desde sua abertura até o encerramento.</li>
                                            <li>Emissão de Certidões dos órgãos do governo e também junto aos cartórios e órgãos privados.</li>
                                            <li>Imposto de Renda Pessoa Física.</li>
                                            <li>Inscrições de Autônomos na Prefeitura Municipal.</li>
                                            <li>Atendimento a Intimações e Notificações de Pessoas Físicas.</li>
                                            <li>Cadastros diversos</li>
                                            <li>Encadernação de Livros Fiscais, talões e outros.</li>
                                        </ul>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            <Footer />
        </div>
    )
}
export default Servicos;