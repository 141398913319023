import React, {useState, useEffect, useCallback} from 'react';
import './style.scss';
import "animate.css/animate.min.css";
import Menu from '../../Components/menu';
import Slide from '../../Components/slideshow';
import Serv from '../../Components/Serv';
import Indices from '../../Components/indices';
import Footer from '../../Components/footer';
import Qualityimg from '../../assets/images/quality.jpg';
import ImgDepart from '../../assets/images/depart.jpg';
import ImgFiscal from '../../assets/images/fiscal.jpg';
import ImgOutros from '../../assets/images/outros.jpg';
import ImgBlog from '../../assets/images/blog.png';
function Home(){
    const [offSet, setOffSet] = useState(0);
    const [toggle, setToggle] = useState(false);
    const handler = useCallback(
        () => {
            setOffSet(document.documentElement.scrollTop);
        }, [setOffSet]
    )
    useEffect(() => {
        window.addEventListener('scroll',handler);
    }, [handler]);
    console.log(offSet)
    function handle(){
        if(toggle === false){
            setToggle(true);
        }else{
            setToggle(false);
        }
    }
    return(
        <div>
            <Menu />
            <Slide />
            <section className="description">
                <div className={offSet > 590 ? 'animate-on' : 'animate-off'}>
                    <h2>Para grandes empresas de todos os tamanhos.</h2>
                    <div className="description__p">
                        <p>Trabalhar com excelência e qualidade para contribuir com o crescimento de seus clientes é a filosofia da Futuro Assessoria Contábil. Pode ser micro, pequena, média ou grande empresa, o que importa para a gente é atender a sua necessidade.</p>  
                    </div>
                </div>
            </section>
            <div className={offSet > 800 ? 'animate-on' : 'animate-off'}>
                <Serv/>
            </div>
            <section className="quality">
                <figure className={offSet > 1400 ? 'animate-on' : 'animate-off'}>
                    <img src={Qualityimg} alt="Imagem de certificados de qualidade da Futuro" />
                </figure>
                <div className={offSet > 1400 ? 'quality__text animate-on' : 'animate-off'}>
                    <h2>Qualidade certificada.</h2>
                    <p>Desde 2013, a Futuro Assessoria Contábil é certificada pelo Programa de Qualificação em Excelência Contínua, que qualifica empresas e seus colaboradores a alcançarem os mais altos níveis de gestão e capacidade operacional.</p>
                </div>
            </section>
            <div className={offSet > 1800 ? 'animate-on' : 'animate-off'}>
                <Indices />
            </div>
            {/* <section className='instagram'>
                <div className={offSet > 2900 ? 'animate-on' : 'animate-off'}>
                    <h2>Acompanhe a FUTURO no Instagram.</h2>
                    <div className="instagram__btn">
                        <button>@futurocontabil</button>
                    </div>
                    <div className="instagram__block">
                        <a href="https://www.instagram.com/" target="_blank" rel="noopener noreferrer"><img src={ImgDepart} alt="" /></a>
                        <a href="https://www.instagram.com/" target="_blank" rel="noopener noreferrer"><img src={ImgFiscal} alt="" /></a>
                        <a href="https://www.instagram.com/" target="_blank" rel="noopener noreferrer"><img src={ImgOutros} alt="" /></a>
                    </div>
                </div>
            </section> */}
            {/* <section className={offSet > 3000 || offSet < 200 || toggle === true ? 'off' : 'blog-fixed '}>
                <img src={ImgBlog} alt="Blog da Futuro"/>
                <div className="blog__text">
                    <p> >  Dicas e informações sobre contabilidade, empreendedorismo e muito mais.</p>
                    <span onClick={handle}>X</span>
                </div>
            </section> */}
            <Footer />
        </div>
    )
}
export default Home;