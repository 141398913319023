import React from 'react';
import Menu from '../../../Components/menu';
import Footer from '../../../Components/footer';
function Vencimentos(){
    return(
        <div>
            <Menu />
            <div className="table__simples">
                <div>
                    <h3 class="text-center"> TABELA de Vencimentos dos Principais Impostos</h3>
                    <div class="table-responsive">
                        <table class="table" width="500" border="0" align="center" cellpadding="0" cellspacing="2" class="table table-bordered table-hover text-center ">
                        <tbody><tr class="g-bg-heading text-uppercase">
                            <th height="63" colspan="2" align="center" bgcolor="#b3b5b7" class="titulo"><strong class="titulo">Vencimento dos Principais Impostos</strong></th>
                        </tr>
                        <tr class="g-bg-heading text-uppercase">
                            <th width="246" scope="row" height="30" align="center" valign="middle" bgcolor="#b3b5b7"><strong>Dia</strong></th>
                            <th width="246" scope="row" height="30" align="center" valign="middle" bgcolor="#b3b5b7"><strong>Imposto</strong></th>
                            </tr>
                        <tr class="g-bg-heading text-uppercase">
                            <th width="246" scope="row" height="30" align="center" valign="middle" bgcolor="#FFFFFF">07</th>
                            <th width="246" scope="row" height="30" align="center" valign="middle" bgcolor="#FFFFFF">FGTS</th>
                            </tr>
                        <tr class="g-bg-heading text-uppercase">
                            <th width="246" scope="row" height="30" align="center" valign="middle" bgcolor="#b3b5b7">10 </th>
                            <th width="246" scope="row" height="30" align="center" valign="middle" bgcolor="#b3b5b7">ISS (SP e Cotia)</th>
                            </tr>
                        <tr class="g-bg-heading text-uppercase">
                            <th width="246" scope="row" height="30" align="center" valign="middle" bgcolor="#FFFFFF">20 </th>
                            <th width="246" scope="row" height="30" align="center" valign="middle" bgcolor="#FFFFFF">DAS Simples</th>
                            </tr>
                        <tr class="g-bg-heading text-uppercase">
                            <th width="246" scope="row" height="30" align="center" valign="middle" bgcolor="#b3b5b7">20 </th>
                            <th width="246" scope="row" height="30" align="center" valign="middle" bgcolor="#b3b5b7">GPS/INSS</th>
                            </tr>
                        <tr class="g-bg-heading text-uppercase">
                            <th width="246" scope="row" height="30" align="center" valign="middle" bgcolor="#FFFFFF">25</th>
                            <th width="246" scope="row" height="30" align="center" valign="middle" bgcolor="#FFFFFF">PIS</th>
                            </tr>
                        <tr class="g-bg-heading text-uppercase">
                            <th width="246" scope="row" height="30" align="center" valign="middle" bgcolor="#b3b5b7">25</th>
                            <th width="246" scope="row" height="30" align="center" valign="middle" bgcolor="#b3b5b7">COFINS</th>
                            </tr>
                        <tr class="g-bg-heading text-uppercase">
                            <th width="246" scope="row" height="30" align="center" valign="middle" bgcolor="#FFFFFF">30 ou 31</th>
                            <th width="246" scope="row" height="30" align="center" valign="middle" bgcolor="#FFFFFF">IRPJ (Trimestral)</th>
                            </tr>
                        <tr class="g-bg-heading text-uppercase">
                            <th width="246" scope="row" height="30" align="center" valign="middle" bgcolor="#b3b5b7">30 ou 31</th>
                            <th width="246" scope="row" height="30" align="center" valign="middle" bgcolor="#b3b5b7">CSLL (Trimestral)</th>
                            </tr>
                        </tbody>
                        </table>
                    </div>
                </div>
            </div>
            
            <Footer />
        </div>

    )
}
export default Vencimentos;