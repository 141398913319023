import React from 'react';
import './style.scss';
import { useForm } from 'react-hook-form';
import Menu from '../../Components/menu';
import Footer from '../../Components/footer';
import { FaPhone, FaEnvelope } from 'react-icons/fa';
import Logo from '../../assets/images/Futuro_Assessoria_contabil.png';
import ScrollTop from '../../Components/ScrollTop';
function Contato(){
    const {register, handleSubmit, errors} = useForm();
    function onSubmit(e) {
        console.log(e)
    }
    return(
        <div>
            <ScrollTop />
            <Menu />
            <div className="contact">
                <h1>Vamos manter contato?</h1>
                <div className="contact__client">
                    <div className="contact__form">
                        <img src={Logo} alt="Logo Futuro"/>
                        <p><FaPhone size={18}/> (11) 3375-9220</p>
                        <a href="mailto:futuro@futurocontabil.com.br"><FaEnvelope size={18}/> futuro@futurocontabil.com.br</a>
                    </div>
                    <form className="contact__form" onSubmit={handleSubmit(onSubmit)}>
                        <div className="contact__name">
                            <input type="text" placeholder="Nome" name="name" ref={register({
                                required: 'Coloque seu nome'
                            })} />
                            {errors.name && <p className="error">{errors.name.message}</p>}
                        </div>
                        <div className="contact__email">
                            <input type="email" placeholder="E-mail" name="email" ref={register({
                                required: 'Coloque seu email',
                                pattern: {
                                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                    message: "Coloque seu email",
                                }
                            })} />
                            {errors.email && <p className="error">{errors.email.message}</p>}
                        </div>
                        <div className="contact__ass">
                            <input type="text" placeholder="Assunto" name="assunto" ref={register({
                                required: 'Coloque o assunto'
                            })} />
                            {errors.assunto && <p className="error">{errors.assunto.message}</p>}
                        </div>
                        <div className="contact__msg">
                            <input type="text" placeholder="Mensagem" name="msg" ref={register({
                                required: 'Escreva sua mensagem'
                            })} />
                            {errors.msg && <p className="error">{errors.msg.message}</p>}
                        </div>
                        <div className="contact__btn">
                            <button>Enviar</button>
                        </div>
                    </form>
                </div>
                
            </div>
            <div className="contact__map">
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3656.4557037700956!2d-46.63785728464984!3d-23.587984368461996!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94ce5a2a3f9f157f%3A0x9555ab7c9c32c17e!2sR.%20Domingos%20de%20Morais%2C%201334%20-%20Vila%20Mariana%2C%20S%C3%A3o%20Paulo%20-%20SP%2C%2004010-200!5e0!3m2!1spt-BR!2sbr!4v1596138588430!5m2!1spt-BR!2sbr" frameborder="0" allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>
            </div>
            <Footer />
        </div>
    )
}
export default Contato;