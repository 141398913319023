import React, {useState} from 'react';
import './style.scss';
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
import Menu from '../../Components/menu';
import { Link } from 'react-router-dom';
import Footer from '../../Components/footer';
import Banner1 from '../../assets/images/indices/tabelas_2.jpg';
import Banner2 from '../../assets/images/indices/tabelas_2a.jpg';
import ScrollTop from '../../Components/ScrollTop';
function Empresa(){
    const [responsive, setResponsive] = useState({
        0: {items: 1}
     })
    return(
        <div>
            <ScrollTop />
            <Menu />
            <section className="empresa indices">
                <div className="empresa__slide">
                <AliceCarousel 
                    responsive={responsive}
                    autoPlayInterval={3000}   
                    autoPlay={true}
                >
                    <div className="servicos-content-box">
                        <figure>
                            <img src={Banner1} alt="Serviços" />
                        </figure>
                    </div>
                    <div className="servicos-content-box">
                        <figure>
                            <img src={Banner2} alt="Serviços" />
                        </figure>
                    </div>
                </AliceCarousel>
                </div>
                <div className="empresa__text">
                    <h2>Índices e tabelas</h2>
                    <ul>
                        <li><Link to="/simples">Simples Nacional</Link></li>
                        <li><Link to="/federal">Tributação Federal Lucro Presumido</Link></li>
                        <li><Link to="/vencimentos">Vencimentos dos Principais Impostos</Link></li>
                        <li><Link to="/contribuicoes">Contribuições Previdenciárias INSS</Link></li>
                        <li><Link to="/salarios">Salário Família e Salário Mínimo</Link></li>
                        <li><Link to="/impostoderenda">Imposto de Renda Retido na Fonte</Link></li>
                    </ul>
                </div>
            </section>
            <Footer />
        </div>
    )
}
export default Empresa;