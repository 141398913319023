import React from 'react';
import './style.scss';
import ImgCont from '../../assets/images/contabilidade.jpg';
import ImgDepart from '../../assets/images/depart.jpg';
import ImgFiscal from '../../assets/images/fiscal.jpg';
import ImgOutros from '../../assets/images/outros.jpg';
import { Link } from 'react-router-dom';

function Services(){
    return(
        <div className="services">
            <div className="services__content">
                <div className="services__box">
                    <Link to="/servicos">
                        <div className="services--enable">
                            <p>Contabilidade</p>
                        </div>
                        <img src={ImgCont} alt="Logo Futuro"/>
                        <div className="services__text">
                            <p>Contabilidade</p>
                        </div>
                    </Link>
                </div>
                <div className="services__box">
                    <Link to="/servicos">
                        <div className="services--enable">
                            <p>Fiscal e tributário</p>
                        </div>
                        <img src={ImgFiscal} alt="Logo Futuro"/>
                        <div className="services__text">
                            <p>Fiscal e tributário</p>
                        </div>
                    </Link>
                </div>
                <div className="services__box">
                    <Link to="/servicos">
                        <div className="services--enable">
                            <p>Departamento Pessoal</p>
                        </div>
                        <img src={ImgDepart} alt="Logo Futuro"/>
                        <div className="services__text">
                            <p>Departamento Pessoal</p>
                        </div>
                    </Link>
                </div>
                <div className="services__box">
                    <Link to="/servicos">
                        <div className="services--enable">
                            <p>Legal e outros</p>
                        </div>
                        <img src={ImgOutros} alt="Logo Futuro"/>
                        <div className="services__text">
                            <p>Legal e outros</p>
                        </div>
                    </Link>
                </div>
            </div>
        </div>
    )
}
export default Services;