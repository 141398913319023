import React, {useState} from 'react';
import './style.scss';
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
import Menu from '../../Components/menu';
import Footer from '../../Components/footer';
import Banner1 from '../../assets/images/empresa/empresa_1.jpg';
import Banner2 from '../../assets/images/empresa/empresa_2.jpg';
import Banner3 from '../../assets/images/empresa/empresa_3.jpg';
import Banner4 from '../../assets/images/empresa/empresa_4.jpg';
import ScrollTop from '../../Components/ScrollTop';
function Empresa(){
    const [responsive , setResponsive] = useState({
        0: {items: 1}
     }
    )
    return(
        <div>
            <ScrollTop />
            <Menu />
            <section className="empresa">
                <div className="empresa__slide">
                <AliceCarousel 
                    responsive={responsive}
                    autoPlayInterval={3000}   
                    autoPlay={true}
                >
                    <div className="servicos-content-box">
                        <figure>
                            <img src={Banner1} alt="Serviços" />
                        </figure>
                    </div>
                    <div className="servicos-content-box">
                        <figure>
                            <img src={Banner2} alt="Serviços" />
                        </figure>
                    </div>
                    <div className="servicos-content-box">
                        <figure>
                            <img src={Banner3} alt="Serviços" />
                        </figure>
                    </div>
                    <div className="servicos-content-box">
                        <figure>
                            <img src={Banner4} alt="Serviços" />
                        </figure>
                    </div>
                </AliceCarousel>
                </div>
                <div className="empresa__text">
                    <h2>A EMPRESA</h2>
                    <p>A FUTURO Assessoria Contábil nasceu com o compromisso de oferecer serviços de qualidade com transparência, segurança e responsabilidade.</p>
                    <p>Acreditamos que seguir princípios éticos pessoais e profissionais é tão importante quanto a qualificação técnica da nossa equipe.</p>
                    <p>É com esse olhar que a FUTURO trabalha e constrói relações duradouras com cada cliente desde 1994.</p>
                </div>
            </section>
            <Footer />
        </div>
    )
}

export default Empresa;