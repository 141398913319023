import React from 'react';
import './style.scss';
import { FaInstagram, FaFacebook, FaWhatsapp } from 'react-icons/fa';
import LogoFooter from '../../assets/images/logo-footer.png';
function Footer(){
    return(
       <div className="footer">
           {/* <div className="footer__news">
                <p>Assine a nossa newsletter e receba conteúdo gratuito. </p>
                <div className="footer__inscrever">
                    <input type="email" placeholder="Seu e-mail"></input>
                    <button>Inscreva-se</button>
                </div>
           </div> */}
           <div className="footer__ends">
                <div className="footer__contato">
                    <div>
                        <h3>Vamos manter contato?</h3>
                        <p>(11) 3375-9220 / futuro@futurocontabil.com.br</p>
                    </div>
                </div>
                <div className="footer__logo">
                    <img src={LogoFooter} alt="Logo Futuro"/>
                </div>
                <div className="footer__visite">
                    <div>
                        <h3>Venha nos visitar.</h3>
                        <p>Domingos de Morais, 1334, D5 - Vila Mariana, São Paulo/SP.</p>
                    </div>
                </div>
                
           </div>
           <div className="footer__rsocial">
                <a href="https://wa.me/5511992963793" target="_blank"><FaWhatsapp size={18}/></a>
                <a href="https://www.facebook.com/Futuro-Assessoria-Cont%C3%A1bil-109286837920445" target="_blank"><FaFacebook size={18}/></a>
                <a href="https://instagram.com/futuro_assessoriacontabil?igshid=po9b6p3v7cav" target="_blank"><FaInstagram size={18}/></a>
           </div>
       </div>
    )
}  
export default Footer;