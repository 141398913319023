import React from 'react';
import Menu from '../../../Components/menu';
import Footer from '../../../Components/footer';
function Vencimentos(){
    return(
        <div>
            <Menu />
            <div className="table__simples">
                <div>
                    <h3 class="text-center"> TABELA Imposto de Renda Retido na Fonte</h3>
                    <div class="table-responsive">
                    <table width="100%" border="1" align="center" cellpadding="1" cellspacing="1">
                        <tbody><tr>
                        <td width="35%" height="40" align="center" bgcolor="#b3b5b7" class="textotabela">Base de cálculo (R$)</td>
                        <td width="32%" align="center" bgcolor="#b3b5b7" class="textotabela">Aliquota(%)</td>
                        <td width="33%" height="40" align="center" bgcolor="#b3b5b7" class="textotabela">Parcela a deduzir (R$)</td>
                        </tr>
                        <tr>
                        <td height="40" align="center" bgcolor="#ffffff" class="textotabela">Até 1.903,98</td>
                        <td align="center" bgcolor="#ffffff" class="textotabela">-</td>
                        <td height="40" align="center" bgcolor="#ffffff" class="textotabela">-</td>
                        </tr>
                        <tr>
                        <td height="40" align="center" bgcolor="#ffffff" class="textotabela">De 1.903,99 até 2.826,65</td>
                        <td align="center" bgcolor="#ffffff" class="textotabela">7,50</td>
                        <td height="40" align="center" bgcolor="#ffffff" class="textotabela">142,80</td>
                        </tr>
                        <tr>
                        <td height="40" align="center" bgcolor="#ffffff" class="textotabela">De 2.826,66 até 3.751,05</td>
                        <td align="center" bgcolor="#ffffff" class="textotabela">15,00</td>
                        <td height="40" align="center" bgcolor="#ffffff" class="textotabela">354,80</td>
                        </tr>
                        <tr>
                        <td height="40" align="center" bgcolor="#ffffff" class="textotabela">De 3.751,06 até 4.664,68</td>
                        <td align="center" bgcolor="#ffffff" class="textotabela">22,50</td>
                        <td height="40" align="center" bgcolor="#ffffff" class="textotabela">636,13</td>
                        </tr>
                        <tr>
                        <td height="40" align="center" bgcolor="#ffffff" class="textotabela">Acima de 4.664,68</td>
                        <td align="center" bgcolor="#ffffff" class="textotabela">27,50</td>
                        <td height="40" align="center" bgcolor="#ffffff" class="textotabela">869,36</td>
                        </tr>
                        <tr>
                        <td height="40" colspan="2" align="center" bgcolor="#ffffff" class="textotabela">Dedução por Dependente</td>
                        <td height="40" align="center" bgcolor="#ffffff" class="textotabela">189,59</td>
                        </tr>
                        </tbody></table>
                    </div>
                </div>
            </div>
            
            <Footer />
        </div>

    )
}
export default Vencimentos;