import React from 'react';
import Menu from '../../../Components/menu';
import Footer from '../../../Components/footer';
function Salarios(){
    return(
        <div>
            <Menu />
            <div className="table__simples">
            <div>
                <h3 align="center">Salário Família e Salário Mínimo</h3>     
                <p align="center">Disponibilizamos abaixo as Tabelas de Salário Família e Salário Mínimo Federal e Estadual (SP).</p>
                <div id="familia">            
                <p>&nbsp;</p>
                
                <h3 class="text-center">TABELA Salário Família 2020</h3>
                
                <div>
                    <div class="table-responsive">
                        <table width="100%" border="1" align="center" cellpadding="1" cellspacing="1">
                        <tbody><tr>
                        <td width="50%" height="40" align="center" bgcolor="#b3b5b7" class="textotabela">Remuneração (R$)</td>
                        <td width="50%" height="40" align="center" bgcolor="#b3b5b7" class="textotabela">Cota do Salário Família (R$)</td>
                        </tr>
                        <tr>
                        <td height="40" align="center" bgcolor="#ffffff" class="textotabela">Até 1.425,56</td>
                        <td height="40" align="center" bgcolor="#ffffff" class="textotabela">48,62 por filho</td>
                        </tr>
                        <tr>
                        <td height="40" align="center" bgcolor="#ffffff" class="textotabela">Acima de 1.425,56</td>
                        <td height="40" align="center" bgcolor="#ffffff" class="textotabela">Não tem direito ao salário famíliaa</td>
                        </tr>
                        <tr>
                        <td height="40" colspan="2" align="center" bgcolor="#ffffff" class="textotabela">Valor por dependente até 14 anos de idade, ou inválido de qualquer idade</td>
                        </tr>
                        </tbody></table>
                    </div>   
                </div>              
                </div>


                <div id="federal">            
                <p>&nbsp;</p>
                
                <h3 class="text-center"><i class="fa fa-table"></i> TABELA Salário Mínimo Federal</h3>
                
                <div class="row">
                    <div class="table-responsive">
                        <table width="100%" border="1" align="center" cellpadding="1" cellspacing="1">
                        <tbody><tr>
                        <td width="50%" height="40" align="center" bgcolor="#b3b5b7" class="textotabela">Vigência</td>
                        <td width="50%" height="40" align="center" bgcolor="#b3b5b7" class="textotabela">Valor (R$)</td>
                        </tr>
                        <tr>
                        <td height="40" align="center" bgcolor="#ffffff" class="textotabela">1 fevereiro/2020</td>
                        <td height="40" align="center" bgcolor="#ffffff" class="textotabela">1.045,00</td>
                        </tr>                   
                        <tr>
                        <td height="40" align="center" bgcolor="#ffffff" class="textotabela">1 janeiro/2020</td>
                        <td height="40" align="center" bgcolor="#ffffff" class="textotabela">1.039,00</td>
                        </tr>                   
                        <tr>
                        <td height="40" align="center" bgcolor="#ffffff" class="textotabela">1 janeiro/2019</td>
                        <td height="40" align="center" bgcolor="#ffffff" class="textotabela">998,00</td>
                        </tr>                     
                        <tr>
                        <td height="40" align="center" bgcolor="#ffffff" class="textotabela">1 janeiro/2018</td>
                        <td height="40" align="center" bgcolor="#ffffff" class="textotabela">954,00</td>
                        </tr>                   
                        <tr>
                        <td height="40" align="center" bgcolor="#ffffff" class="textotabela">1 janeiro/2017</td>
                        <td height="40" align="center" bgcolor="#ffffff" class="textotabela">937,00</td>
                        </tr>
                        <tr>
                        <td height="40" align="center" bgcolor="#ffffff" class="textotabela">1 janeiro/2016</td>
                        <td height="40" align="center" bgcolor="#ffffff" class="textotabela">880,00</td>
                        </tr>
                        <tr>
                        <td height="40" align="center" bgcolor="#ffffff" class="textotabela">1 janeiro/2015</td>
                        <td height="40" align="center" bgcolor="#ffffff" class="textotabela">788,00</td>
                        </tr>
                        <tr>
                        <td height="40" align="center" bgcolor="#ffffff" class="textotabela">1 janeiro/2014</td>
                        <td height="40" align="center" bgcolor="#ffffff" class="textotabela">724,00</td>
                        </tr>
                        <tr>
                        <td height="40" align="center" bgcolor="#ffffff" class="textotabela">1 janeiro/2013</td>
                        <td height="40" align="center" bgcolor="#ffffff" class="textotabela">678,00</td>
                        </tr>
                        <tr>
                        <td height="40" align="center" bgcolor="#ffffff" class="textotabela">1 janeiro/2012</td>
                        <td height="40" align="center" bgcolor="#ffffff" class="textotabela">622,00</td>
                        </tr>
                        </tbody></table>
                    </div>  
                </div>              
                </div>


                <div id="estadual">           
                <p>&nbsp;</p>
                
                <h3 class="text-center"><i class="fa fa-table"></i> TABELA Salário Mínimo Estadual (SP)</h3>
                
                <div class="row">
                        <div class="table-responsive">
                        <table width="100%" border="1" align="center" cellpadding="1" cellspacing="1">
                        <tbody><tr>
                            <td height="62" colspan="2" align="center" bgcolor="#b3b5b7" class="textotabela">Salário Minímo no Estado de São Paulo<br />
                            A partir de 1 de abril de 2019</td>
                        </tr>
                        <tr>
                            <td width="16%" height="40" align="center" bgcolor="#b3b5b7" class="textotabela">Salário</td>
                            <td width="84%" align="center" bgcolor="#b3b5b7" class="textotabela">Atividades</td>
                        </tr>
                        <tr>
                            <td height="109" align="center" bgcolor="#ffffff" class="textotabela">R$ 1.163,55</td>
                            <td align="center" valign="middle" bgcolor="#ffffff" class="textotabela">Trabalhadores domésticos, serventes, trabalhadores agropecuários e florestais, pescadores, contínuos, mensageiros e trabalhadores de serviços de limpeza e conservação, trabalhadores de serviços de manutenção de áreas verdes e de logradouros públicos, auxiliares de serviços gerais de escritório, ascensoristas, “motoboys”, carteiros, tintureiros, barbeiros, cabeleireiros, manicures e pedicures, dedetizadores, vendedores, trabalhadores de costura e estofadores, pedreiros, garçons, operadores de telefone e de “telemarketing”, entre outros.</td>
                        </tr>
                        <tr>
                            <td height="85" align="center" bgcolor="#ffffff" class="textotabela">R$ 1.183,33</td>
                            <td align="center" valign="middle" bgcolor="#ffffff" class="textotabela">Administradores agropecuários e florestais, trabalhadores de serviços de higiene e saúde, chefes de serviços de transportes e de comunicações, supervisores de compras e de vendas, agentes técnicos em vendas e representantes comerciais, operadores de estação de rádio e de estação de televisão, de equipamentos de sonorização e de projeção cinematográfica e técnicos em eletrônica.</td>
                        </tr>
                        </tbody></table>
                    </div>  
                </div>              
                </div>          
            </div>
            </div>
            
            <Footer />
        </div>

    )
}
export default Salarios